var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      !_vm.userManager.checkRole([
        'kt',
        'mkt',
        'tp',
        'vdl',
        'vd',
        'telesale',
        'cdc',
        'qlk',
        'design',
        'content',
        'ksnb',
        'ksnbl' ])
    )?_c('div',[_vm._v(" Bạn không có quyền truy cập trang này ")]):_c('div',[_c('v-tabs',{attrs:{"height":"28px","dense":"","show-arrows":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_vm._l((_vm.tabs),function(item,key){return [(!item.condition || item.condition(_vm.self))?_c('v-tab',{key:key,attrs:{"to":("/app/markets/" + (_vm.$route.params.marketId) + "/orders/" + key)}},[(item.icon)?_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(item.label)+" ")],1):_vm._e()]})],2),_c('div',{staticClass:"mt-4"},[_c('router-view')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }