










































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      item: null,
      currentTab: "",
      tabs: {
        index: {
          label: "Tất cả đơn hàng",
          icon: "mdi-check-decagram-outline",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "vdl", "vd", "ksnb", "ksnbl", "design", "content"]);
          },
        },
        invalid_check: {
          label: "Check trùng đơn",
          icon: "mdi-check",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "ksnb", "ksnbl"]);
          },
        },
        telesale_check: {
          label: "Check telesale",
          icon: "mdi-phone",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "telesale", "ksnb", "ksnbl"]);
          },
        },
        location_check: {
          label: "Check địa chỉ",
          icon: "mdi-map-marker",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "cdc", "ksnb", "ksnbl"]);
          },
        },
        pick_up_prepare: {
          label: "Đơn đủ điều kiện gửi đi",
          icon: "mdi-cart",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "ksnb", "ksnbl"]);
          },
        },
        pick_up_ready: {
          label: "Sẵn sàng xuất đi",
          icon: "mdi-cart",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "ksnb", "ksnbl"]);
          },
        },
        to_be_picked_up: {
          label: "Chờ pick up",
          icon: "mdi-cart-check",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "ksnb", "ksnbl"]);
          },
        },
        to_be_delivered: {
          label: "Chờ xuất kho",
          icon: "mdi-warehouse",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "qlk", "ksnb", "ksnbl"]);
          },
        },
        delivered: {
          label: "Đã xuất kho",
          icon: "mdi-truck-fast",
          condition({ item }) {
            return item && !item.tmdt && userManager.checkRole(["kt", "vdl", "vd", "ksnb", "ksnbl"]);
          },
        },
        telesale_confirm: {
          label: "Telesale confirm",
          icon: "mdi-phone",
          condition({ item }) {
            return item && !item.tmdt && item.code.includes("telesale") && userManager.checkRole(["kt", "telesale", "vd", "vdl"]);
          },
        },
      },
    };
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
  },
});
